import React, { createContext,  useEffect,  useState  } from 'react'
//import all_product from '../Components/Assets/all_product'

export const ShopContext = createContext(null);

const getDefaultCart = () =>{
    let cart = {};
    for (let index = 0; index < 8; index++) {
        cart[index] = 0;
    }
    return cart;
}

const ShopContextProvider = (props)=> {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const [cartItems,setCartItems] = useState(getDefaultCart());
    const [userDetails, setUserDetails] = useState(null);
    const [tempOrder, setTempOrder] = useState({
        shippingAddress: null,
        billingAddress: null,
        shippingCost: null,
        paymentMethod:null,
        phone:null,
        orderTotal: 0,
        cartItems: getDefaultCart(),
        stripesession: null
    });
        // Fonction pour mettre à jour tempOrder
        const updateTempOrder = (updates) => {
            setTempOrder((prevOrder) => ({
                ...prevOrder,
                ...updates,
            }));
        };
    
        // Effect pour synchroniser cartItems avec tempOrder
        useEffect(() => {
            updateTempOrder({ cartItems });
        }, [cartItems]);

        
    useEffect(()=>{

        if (localStorage.getItem('auth-token')) {
            fetch(`${backendUrl}/getcart`, {
                method: 'POST',
                headers: {
                    Accept: 'application/form-data',
                    'auth-token': `${localStorage.getItem('auth-token')}`,
                    'Content-type': 'application/json',
                },
                body: "",
            }).then((response) => response.json())
                .then((data) => setCartItems(data));

            fetch(`${backendUrl}/getaccountdetails`, {
                method: 'POST',
                headers: {
                    Accept: 'application/form-data',
                    'auth-token': `${localStorage.getItem('auth-token')}`,
                    'Content-type': 'application/json',
                },
                body: "",
            }).then((response) => response.json())
                .then((data) => setUserDetails(data));
        }
    }, [backendUrl]);
    //console.log(cartItems);

    const addToCart = (itemId) => {
        setCartItems((prev)=>({...prev,[itemId]:prev[itemId]+1}))
        if (localStorage.getItem('auth-token')){
            fetch(`${backendUrl}/addtocart`,{
                method:'POST',
                headers:{
                    Accept:'application/form-data',
                    'auth-token':`${localStorage.getItem('auth-token')}`,
                    'Content-type':'application/json',
                },
                body:JSON.stringify({"itemId":itemId}),
            })
            .then((response)=>response.json())
            .then((data)=>console.log(data))
        }else{
            /*console.log('prout');*/
        }
    }
    const removeFromCart = (itemId) => {
        setCartItems((prev)=>({...prev,[itemId]:prev[itemId]-1}))
        console.log(cartItems);
        if (localStorage.getItem('auth-token')){
            fetch(`${backendUrl}/removefromcart`,{
                method:'POST',
                headers:{
                    Accept:'application/form-data',
                    'auth-token':`${localStorage.getItem('auth-token')}`,
                    'Content-type':'application/json',
                },
                body:JSON.stringify({"itemId":itemId}),
            })
            .then((response)=>response.json())
            .then((data)=>console.log(data))
        }else{
            /*console.log('prout removed');*/
        }
    }

    /*
   const clearCart = async () => {
        setCartItems(getDefaultCart()); // Réinitialiser le panier local
    };*/
    const clearCart = React.useCallback(async () => {
        setCartItems(getDefaultCart()); // Réinitialiser le panier local
    }, []);

    const getTotalCartItems = () => {
        let totalItem = 0;
        for(const item in cartItems){
            if (cartItems[item]>0){
                totalItem+=cartItems[item];
            }

        }
        return totalItem;
    }

    const updateUserDetails = async (updatedDetails) => {
        try {
          const response = await fetch(`${backendUrl}/updateuserdetails`, {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'auth-token': `${localStorage.getItem('auth-token')}`,
              'Content-type': 'application/json',
            },
            body: JSON.stringify(updatedDetails),
          });
      
          const data = await response.json();
      
          if (data.success) {
            setUserDetails((prevDetails) => ({
              ...prevDetails,
              ...updatedDetails,
            }));
          }
          return data; // return the response data
        } catch (error) {
          console.error('Error updating user details:', error);
          return { success: false, errors: error.message }; // return an error response
        }
      };

    const contextValue={cartItems,addToCart,removeFromCart,getTotalCartItems,userDetails,updateUserDetails,tempOrder,setTempOrder,updateTempOrder,clearCart};

    return (
        <ShopContext.Provider value={contextValue}>
            {props.children}
        </ShopContext.Provider>
    )
}
export default ShopContextProvider;