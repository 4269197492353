import React, { useContext, useEffect, useState,useCallback } from 'react';
import './CartItems.css';
import { ShopContext } from '../../Context/ShopContext';
import remove_icon from '../Assets/cart_cross_icon.png';
import { debounce } from 'lodash';
import { Link, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

const CartItems = () => {
    const { cartItems, addToCart, removeFromCart } = useContext(ShopContext);
    const { t } = useTranslation();
    const location = useLocation();
    const frontendUrl = process.env.REACT_APP_FRONTEND_URL;
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    // Extraire la langue de l'URL
    const pathParts = location.pathname.split('/');
    const currentLang = pathParts[1] || 'en';

    // État local pour stocker les informations des produits récupérées du backend
    const [productData, setProductData] = useState({});

    // Calculate the total quantity of items in the cart
    const totalItemsInCart = Object.values(cartItems).reduce((acc, quantity) => acc + quantity, 0);

    // Handle quantity change (increase up to 20) with debounce
    const handleQuantityClick = debounce((idstore) => {
        if (cartItems[idstore] < 20) {
            addToCart(idstore);
        }
    }, 300);

    // Utilisation de useCallback pour mémoriser la fonction
    const fetchProductDetails = useCallback(async (idstore) => {
        try {
            const response = await fetch(`${backendUrl}/product/${idstore}`);
            const data = await response.json();
            if (data.success) {
                setProductData(prevState => ({
                    ...prevState,
                    [idstore]: data.product
                }));
            } else {
                console.error(`Product with idstore ${idstore} not found`);
            }
        } catch (error) {
            console.error(`Error fetching product with idstore ${idstore}:`, error);
        }
    }, [backendUrl]);

    useEffect(() => {
        Object.keys(cartItems).forEach(idstore => {
            if (cartItems[idstore] > 0) {
                fetchProductDetails(idstore);
            }
        });
    }, [cartItems, fetchProductDetails]); // Tableau de dépendances ajusté

    // Calculer le total de tous les produits dans le panier
    const totalCartAmount = Object.keys(cartItems).reduce((total, idstore) => {
        const product = productData[idstore];
        const quantity = cartItems[idstore];
        if (product && quantity > 0) {
            return total + (product.pricettc * quantity);
        }
        return total;
    }, 0);

    return (
        <div className='cartitems'>
            <div className="cartitems-format-main">
                <p>{t('products')}</p>
                <p></p>
                <p>{t('price')}</p>
                <p>{t('quantity')}</p>
                <p>{t('total')}</p>
                <p></p>
            </div>
            <hr />

            {Object.keys(cartItems).map(idstore => {
                const quantity = cartItems[idstore];
                const product = productData[idstore];

                // Afficher uniquement si la quantité est > 0 et que les données produit sont disponibles
                if (quantity > 0 && product) {
                    return (
                        <div key={idstore}>
                            <div className="cartitems-format cartitems-format-main">
                                <img src={`${frontendUrl}/${product.image1}`} alt={product.name} className='carticon-product-icon' />
                                <p>{currentLang === 'en' ? product.name_en : product.name}</p>
                                <p>{product.pricettc.toFixed(2)} €</p>
                                <button className='cartitems-quantity' onClick={() => handleQuantityClick(idstore)}>
                                    {quantity}
                                </button>
                                <p>{(product.pricettc * quantity).toFixed(2)} €</p>
                                <img className='cartitems-remove-icon' src={remove_icon} onClick={() => { removeFromCart(idstore) }} alt={t('remove')} />
                            </div>
                            <hr />
                        </div>
                    );
                }
                return null;
            })}
            
            <div className="cartitems-down">
                <div className="cartitems-total">
                    <div>
                        <div className="cartitems-total-item">
                            <p>{t('subtotal')}</p>
                            <p>{totalCartAmount.toFixed(2)} €</p> {/* Affichage du total calculé */}
                        </div>
                        <hr />
                        <div className="cartitems-total-item">
                            <p>{t('shipping')}</p>
                            <p>{t('dependsOnShippingAddress')}</p>
                        </div>
                        <hr />
                        <div className="cartitems-total-item">
                            <h3>{t('total')}</h3>
                            <h3>{totalCartAmount.toFixed(2)} €</h3> {/* Affichage du total calculé */}
                        </div>
                    </div>
                    {totalItemsInCart > 0 && (
                        <Link to={`/${currentLang}/checkout`}>
                            <Button className='cartitems-main-button' sx={{ marginTop: 3, marginBottom: 1 }}>
                                {t('proceedToCheckout')}
                            </Button>
                        </Link>
                    )}
                </div>
                <div className="cartitems-promocode">
                    {/* Add your promo code component here */}
                </div>
            </div>
        </div>
    );
};

export default CartItems;
