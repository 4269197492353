import React, { useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Importation pour la traduction
import { ShopContext } from '../../Context/ShopContext';
import amppng256 from '../Assets/amp2.png';
import logocc from '../Assets/logocc.png';
import './ProcessValidationPP.css';

const ProcessValidationPP = () => {
  const { t } = useTranslation(); // Utilisation de la traduction
  const { clearCart } = useContext(ShopContext);
  const location = useLocation();
  const pathParts = location.pathname.split('/');
  const currentLang = pathParts[1] || 'en';
  const queryParams = new URLSearchParams(location.search);
  const orderId = queryParams.get("levampeorder");
  const statusorder = queryParams.get("status");
  

 useEffect(() => {
          clearCart();

  },[clearCart] );

  return (
    <div className='processvalidation-main'>
            <h1>{t('processValidation.completeTitle')}</h1>
          <h2>{t('processValidation.completeSubtitle', { orderId })}</h2>
          <p>{t('processValidation.trackOrder')} <Link to={`/${currentLang}/accountorders`}>{t('processValidation.here')}</Link>.</p>
          <div><img src={amppng256} alt="AMP Logo" /></div>
          <div><img src={logocc} alt="AMP Logo" /></div>
    </div>
  );
};

export default ProcessValidationPP;
