import React from 'react'
import Footer from '../Components/Footer/Footer'
import ContactDisplay from '../Components/ContactDisplay/ContactDisplay'
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next'; 
const Contact = () => {
    const {  i18n } = useTranslation(); // Utilise le hook pour accéder aux traductions
    const canonicalUrl = `${window.location.origin}/${i18n.language}/about`;
  return (
    <div>
        <Helmet>
        <title>Contact</title>
        <meta name="description" content="Contact" />
        <meta name="keywords" content="contact" />
        <link rel="canonical" href={canonicalUrl} /> 
      </Helmet>
      <ContactDisplay></ContactDisplay>
      <Footer></Footer>
    </div>
  )
}

export default Contact