import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Navbar from './Navbar';
import './Accountorders.css';
import { 
  Accordion, 
  AccordionSummary, 
  AccordionDetails, 
  Typography, 
  CircularProgress, 
  Alert, 
  Button 
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

// Fonction pour convertir le statut de la commande en texte
const OrderStatusText = ({ status }) => {
  const { t } = useTranslation();
  switch (status) {
    case 1:
      return 'Pending'; // erreur avant paiement
    case 2:
      return t('accountorders.preparation'); // En cours de préparation
    case 3:
      return t('accountorders.await'); // En attente d'approvisionnement
    case 4:
      return t('accountorders.Cancelled'); // Annulé
    case 5:
      return t('accountorders.validation'); // Validation interne
    case 6:
      return t('accountorders.shipped');
    default:
      return t('accountorders.unknown');; // Statut inconnu
  }
};

// Fonction pour générer le PDF
const generatePDF = (order) => {
  
  const doc = new jsPDF();
  doc.setFontSize(18);
  doc.text(`CustomControlz`, 15, 10);
  doc.setFontSize(14);

  doc.text("Facture", 170, 10);

  doc.roundedRect(120, 30, 80, 30, 5, 5, 'S')
  doc.setFontSize(12);
  doc.text(`Numero: ${order.orderId}`, 170, 15);
  doc.text(`Date: ${new Date(order.date).toLocaleDateString()}`, 170, 20);

  doc.text('Adresse de facturation:', 125, 35);
  doc.text(`${order.firstname} ${order.lastname}`, 125, 40);
  doc.text(`${order.street1billing} ${order.street2billing ? `, ${order.street2billing}` : ''}`, 125, 45);
  doc.text(`${order.citybilling}, ${order.countrybilling} - ${order.postalcodebilling}`, 125, 50);
  // Adresses
  doc.roundedRect(10, 30, 80, 30, 5, 5, 'S')
  doc.text('Adresse de livraison:', 15, 35);
  doc.text(`${order.firstname} ${order.lastname}`, 15, 40);
  doc.text(`${order.street1shipping} ${order.street2shipping ? `, ${order.street2shipping}` : ''}`, 15, 45);
  doc.text(`${order.cityshipping}, ${order.countryshipping} - ${order.postalcodeshipping}`, 15, 50);

  const tableColumn = ["Produit", "Quantité", "Prix", "Total"];
  const tableRows = order.products.map((product) => [
    product.name,
    product.quantity,
    product.pricettc.toFixed(2),
    (product.quantity * product.pricettc).toFixed(2)
  ]);
  doc.autoTable(tableColumn, tableRows, { startY: 80 });
  doc.setFontSize(10);
  doc.text(`Frais de port: ${order.totals.calculatedShipping / 100} €`, 15, doc.autoTable.previous.finalY + 20);
  doc.text(`Total payé par carte (Stripe): ${order.totals.totalWithShipping / 100} €`, 15, doc.autoTable.previous.finalY + 25);
  
  doc.setFontSize(8);
  doc.text(`CustomControlz - 932 268 840 R.C.S. Bordeaux - 33114 Le Barp`, 15, 280);
  doc.text(`TVA non applicable, article 293B du CGI`, 15, 285);

  /*doc.text(`CustomControlz - SIREN: 932 268 840 `, 10, doc.autoTable.previous.finalY + 80);
  doc.text(`TVA non applicable, article 293B du CGI`, 10, doc.autoTable.previous.finalY + 90);*/
  doc.save(`invoice_${order.orderId}.pdf`);
};

const Accountorders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fetch(`${backendUrl}/get-orders`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'auth-token': localStorage.getItem('auth-token') // Assurez-vous d'avoir le token dans le localStorage
          }
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
      // Normalisation des données
      const normalizedData = data.map(order => ({
        ...order,
        totals: {
          ...order.totals,
          calculatedShippingCost: order.totals?.calculatedShippingCost ?? order.totals?.calculatedShipping ?? 0,
        },
      }));
      setOrders(normalizedData);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [backendUrl]);

  return (
    <div className='accountorders'>
      <Navbar />
      <div className="accountorders-main">
        <h1>{t('accountorders.title')}</h1>
        {loading && <CircularProgress />}
        {error && <Alert severity="error">Error: {error}</Alert>}
        {orders.length === 0 ? (
          <Typography>{t('accountorders.noorder')}</Typography>
        ) : (
          orders
            .filter(order => order.orderStatus !==0) // Filtrer les commandes avec un orderStatus != 0
            .map((order) => (
              
              <Accordion key={order._id}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${order._id}-content`}
                  id={`panel${order._id}-header`}
                  sx={{
                    padding: '8px',
                    '& .MuiTypography-root': {
                      fontSize: '18px', // Ajustez la taille de la police selon vos besoins
                      fontFamily: 'Poppin',
                      color:'#11385a',
                    }
                  }}
                >
                  <Typography variant="h3">
                  {t('accountorders.ordersid')}: <b>{order.orderId}</b> | <OrderStatusText status={order.orderStatus} /> | Date: {new Date(order.date).toLocaleDateString()} | Total: <b>{(order.totals.totalWithShipping / 100).toFixed(2)} € | {order.tracking ? `Suivi colis: ${order.tracking} - Colissimo` : ''}</b>
                  {(order.orderStatus === 2 || order.orderStatus === 6) && (
  <Button 
    variant="contained" 
    color="primary" 
    onClick={() => generatePDF(order)} 
    sx={{ marginLeft: 2 }}
  >
    {t('accountorders.download')}
  </Button>
)}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="accountorders-address">
                    <div>
                      <h2>{t('address.shipping')}:</h2>
                      {order.firstname} {order.lastname}<br />
                      {order.street1shipping} {order.street2shipping ? `, ${order.street2shipping}` : ''}<br />
                      {order.cityshipping}, {order.countryshipping} - {order.postalcodeshipping}
                      <h2>{order.transporter}</h2>
                      <h2>{order.relai_info?.Nom || ''}</h2>
                    </div>
                    <div>
                      <h2>{t('address.billing')}:</h2>
                      {order.firstname} {order.lastname}<br />
                      {order.street1billing} {order.street2billing ? `, ${order.street2billing}` : ''}<br />
                      {order.citybilling}, {order.countrybilling} - {order.postalcodebilling}
                    </div>
                  </div>
                  <div className="accountorders-products">
                    <hr />
                    {order.products.map((product, index) => (
                      <p key={index}>
                        <strong>{product.name}</strong> - {product.quantity} x {(product.pricettc).toFixed(2)} € = {(product.quantity * product.pricettc).toFixed(2)} €
                      </p>
                    ))}
                    <hr />
                  </div>
                  <div className="accountorders-total">
                  <p>{t('cgv.section4.shippingCosts.title')} {((order.totals.calculatedShipping ?? 0) / 100).toFixed(2)} €</p>
                    <p><b>Total: {(order.totals.totalWithShipping / 100).toFixed(2)} €</b></p>
                  </div>
                </AccordionDetails>
              </Accordion>
            ))
        )}
      </div>
    </div>
  );
};

export default Accountorders;
