import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { ShopContext } from '../../Context/ShopContext';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import './CheckoutPaymentPP.css';

const CheckoutForm = () => {
    const { tempOrder } = useContext(ShopContext); // Accéder à la commande dans le contexte
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const navigate = useNavigate();
    const { i18n } = useTranslation();
    const currentLang = i18n.language || 'en';
    const paypalkey=process.env.REACT_APP_PAYPAL_KEY;
    let paypallang="fr_FR";

    if (currentLang!=='fr'){
        paypallang="en_US";
    }    
    console.log(paypallang);
    // État pour vérifier si la commande PayPal a déjà été créée
    const [orderCreated, setOrderCreated] = useState(false);
    //console.log(tempOrder);

    useEffect(() => {
        // Si la commande a déjà été créée, éviter les appels supplémentaires
        if (orderCreated) return;
    }, [orderCreated]); // Ajout du flag pour surveiller l'état de la création de la commande


  // Effet pour gérer le fetch du client secret
  useEffect(() => {
    if (!tempOrder.shippingAddress) {
      navigate(`/${currentLang}/cart`);
      return;
    }

  }, [tempOrder.shippingAddress, navigate, currentLang]);


    // Fonction de création de commande avec l'option shipping_preference
    const createOrder = async (data, actions) => {
        try {
          //console.log(tempOrder);
            if (orderCreated) return; // Si la commande est déjà créée, on ne refait pas l'appel

            // Créer la commande côté serveur
            const response = await fetch(`${backendUrl}/create-checkout-session-pp`, {
              method: "POST",
              headers: {
                'Content-Type': 'application/json',
                'auth-token': `${localStorage.getItem('auth-token')}` // Ajouter le auth-token dans les en-têtes
              },
              body: JSON.stringify(tempOrder),
            });

            const orderData = await response.json();
            //console.log(orderData.id);
            if (orderData.id) {
                setOrderCreated(true); // Marquer la commande comme créée
                // Au lieu de recréer la commande, utilise directement l'ID fourni par le backend
                return orderData.id;
            } else {
                throw new Error("Erreur lors de la création de la commande PayPal.");
            }

        } catch (error) {
            console.error("Erreur lors de la création de la commande PayPal:", error);
            alert("Impossible de démarrer le paiement PayPal.");
        }
    };

    // Fonction lors de l'approbation de la commande
    const onApprove = async (data, actions) => {
        try {
            // Capture du paiement sur le serveur
            const response = await fetch(`${backendUrl}/capture-paypal-order/${data.orderID}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'auth-token': `${localStorage.getItem('auth-token')}` // Ajouter le auth-token dans les en-têtes
                },
                body: JSON.stringify({
                    orderID: data.orderID,
                }),
            });

            const orderData = await response.json();
            const errorDetail = orderData?.details?.[0];
            //console.log(orderData);
            if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                return actions.restart(); // Redémarrer si l'instrument est refusé
            } else if (errorDetail) {
                throw new Error(`${errorDetail.description} (${orderData.debug_id})`); // Gestion des autres erreurs
            } else if (!orderData.purchase_units) {
                throw new Error("Aucune unité d'achat trouvée dans les données.");
            } else {
                const transaction =
                    orderData?.purchase_units?.[0]?.payments?.captures?.[0] ||
                    orderData?.purchase_units?.[0]?.payments?.authorizations?.[0];
                const orderID = data.orderID; // ID de la commande
                const levampeorder = orderData.levampeorder; // ID de la commande
                const status = transaction?.status || "unknown"; // Statut de la commande
                console.log(orderID);
                console.log(levampeorder);
                console.log(status);
                // Success, navigate to the validation page
                navigate(`/${currentLang}/checkoutvalidationpp?levampeorder=${levampeorder}&status=${status}`);
            }
        } catch (error) {
            console.error("Erreur lors de l'approbation du paiement PayPal:", error);
            alert("Désolé, le paiement n'a pas pu être traité.");
        }
    };

    return (
        <div id="checkout" className="checkoutpayment-mainpp">
            <PayPalScriptProvider options={{ clientId: paypalkey, currency: "EUR", locale: paypallang }}>
                <PayPalButtons
                    fundingSource="paypal"
                    createOrder={createOrder} // Fonction pour créer la commande
                    onApprove={onApprove} // Fonction lors de l'approbation de la commande
                    style={{
                        shape: "rect",
                        layout: "vertical",
                        color: "white",
                        label: "paypal",
                    }}
                    shippingPreference="NO_SHIPPING" // Désactive l'adresse de livraison
                    forceUpdate
                />
            </PayPalScriptProvider>
        </div>
    );
};

const CheckoutPaymentPP = () => {
    return (
        <div className="CheckoutPaymentPP">
            <CheckoutForm />
        </div>
    );
};

export default CheckoutPaymentPP;
