import React, { useEffect, useState } from 'react';
import Item from '../Item/Item';
import './Collection.css';
import { useTranslation } from 'react-i18next';

const Collection = () => {
  const { t } = useTranslation();
  const [products, setProducts] = useState([]); // État pour stocker les produits
  const [loading, setLoading] = useState(true); // État pour gérer le chargement
  const [error, setError] = useState(null); // État pour gérer les erreurs

  // URL du backend récupérée depuis le fichier .env
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  // Appeler l'API pour récupérer les produits
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        //const response = await fetch(`${backendUrl}/products`); // Utilisation de l'URL du backend
        const response = await fetch(`${backendUrl}/products`); // Utilisation de l'URL du backend
        if (!response.ok) {
          throw new Error('Erreur lors du chargement des produits');
        }
        const data = await response.json();
        setProducts(data); // Mettre à jour l'état avec les produits filtrés
        setLoading(false); // Désactiver le chargement une fois les produits récupérés
      } catch (err) {
        setError(err.message); // Gérer les erreurs
        setLoading(false); // Désactiver le chargement en cas d'erreur
      }
    };

    fetchProducts();
  }, [backendUrl]); // Le tableau vide signifie que l'effet est exécuté une seule fois après le montage

  if (loading) {
    return <p>{t('collection.loading')}</p>; // Affichage d'un message de chargement
  }

  if (error) {
    return <p>{t('collection.error')}: {error}</p>; // Affichage d'un message d'erreur
  }

  return (
    <div className='collection'>
      <div className="collection-title">
        <h1>{t('collection.title')}</h1>
      </div>
      <hr />
      <div className="collections">
        {products.map((item, i) => (
          <Item
            key={i}
            id={item.idstore} // Assurez-vous que ce champ correspond à l'ID du produit dans MongoDB
            name={item.name}
            name_en={item.name_en}
            image={item.image1} // Assurez-vous que le nom du champ correspond à votre schéma MongoDB
            new_price={item.pricettc}
            old_price={item.oldpricettc}
            tax={item.tax}
          />
        ))}
      </div>
    </div>
  );
};

export default Collection;
