import React from 'react'
import { Helmet } from 'react-helmet-async';
import CgvDisplay from '../Components/CgvDisplay/cgvdisplay.jsx'
import Footer from '../Components/Footer/Footer'
import { useTranslation } from 'react-i18next'; 

const Cgv = () => {
    const { t, i18n } = useTranslation(); // Utilise le hook pour accéder aux traductions
    const canonicalUrl = `${window.location.origin}/${i18n.language}/cgv`;
  return (
    <div>
              <Helmet>
              <title>{t('seo.cgv_title')}</title>
              <meta name="description" content={t('seo.cgv_description')} />
              <meta name="keywords" content={t('seo.cgv_keywords')} />
              <link rel="canonical" href={canonicalUrl} /> 
            </Helmet>
      <CgvDisplay></CgvDisplay>
      <Footer></Footer>
    </div>
  )
}

export default Cgv