import React, { useCallback, useState, useEffect, useContext, useRef } from "react";
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { useNavigate, useLocation } from "react-router-dom";
import { ShopContext } from '../../Context/ShopContext';
import './CheckoutPayment.css';

//const stripePromise = loadStripe("pk_live_51PttPrGzfgiJZFHmWT5RwPXeI4guOLnUUTqbYMvd6L9spKmQF1JcjQt6EblMakP5dC4Ymhibjo7SBaDDC9Wt2noK00DppUaNC4");
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const CheckoutForm = () => {
  const { tempOrder } = useContext(ShopContext);
  const [clientSecret, setClientSecret] = useState('');
  const navigate = useNavigate();
  const fetchedRef = useRef(false); // Pour éviter les appels multiples
  const location = useLocation(); // Utiliser useLocation pour obtenir l'URL
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  // Extraire la langue de l'URL
  const pathParts = location.pathname.split('/');
  const currentLang = pathParts[1] || 'en'; 

  // Déclaration de fetchClientSecret
  const fetchClientSecret = useCallback(() => {
    fetch(`${backendUrl}/create-checkout-session?currentlang=` + currentLang, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'auth-token': `${localStorage.getItem('auth-token')}` // Ajouter le auth-token dans les en-têtes
      },
      body: JSON.stringify(tempOrder),
    })
    .then((res) => res.json())
    .then((data) => setClientSecret(data.clientSecret))
    .catch((error) => console.error('Error fetching client secret:', error));
  }, [tempOrder, currentLang,backendUrl]);

  // Effet pour gérer le fetch du client secret
  useEffect(() => {
    if (!tempOrder.shippingAddress) {
      navigate(`/${currentLang}/cart`);
      return;
    }

    if (!fetchedRef.current) { // Si l'API n'a pas encore été appelée
      fetchedRef.current = true; // Marque l'appel comme effectué
      fetchClientSecret();
    }
  }, [tempOrder.shippingAddress, navigate, currentLang, fetchClientSecret]);

  const options = { clientSecret };

  return (
    <div id="checkout" className="checkoutpayment-main">
      {clientSecret && (
        <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </div>
  );
};

const CheckoutPayment = () => {
  return (
    <div className="CheckoutPayment">
      <CheckoutForm />
    </div>
  );
};

export default CheckoutPayment;
