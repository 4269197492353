import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next'; 
import { useParams } from 'react-router-dom';
import DescriptionBox from '../Components/DescriptionBox/DescriptionBox';
import ProductDisplay from '../Components/ProductDisplay/ProductDisplay';
import Footer from '../Components/Footer/Footer';

const Product = () => {
  const { productId } = useParams();
  
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const { t, i18n } = useTranslation(); 
  const canonicalUrl = `${window.location.origin}/${i18n.language}/product/${productId}`;

 // Générer les URLs hreflang dynamiquement en utilisant les langues de i18next
 const getHreflangUrls = () => {
  return i18n.languages.map((lang) => ({
    hreflang: lang,
    href: `${window.location.origin}/${lang}/product/${productId}`,
  }));
};


  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await fetch(`${backendUrl}/product/${productId}`);
        const data = await response.json();

        if (data.success) {
          setProduct(data.product);
        } else {
          setError('Produit non trouvé');
        }
      } catch (err) {
        setError('Erreur lors de la récupération des informations du produit');
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [productId,backendUrl]);

  if (loading) {
    return <div>Chargement en cours...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      {product ? (
        <>
          <Helmet>
            <title>{`${product.name} | `}{t('seo.product_title')}</title>
            <meta name="description" content={t('seo.product_description')} />
            <meta name="keywords" content={t('seo.product_keywords')} />
            <link rel="canonical" href={canonicalUrl} />
            {getHreflangUrls().map(({ hreflang, href }) => (
              <link key={hreflang} rel="alternate" hreflang={hreflang} href={href} />
            ))}
          </Helmet>
          <ProductDisplay product={product} />
          <DescriptionBox product={product} />
          <Footer />
        </>
      ) : (
        <div>Produit introuvable</div>
      )}
    </div>
  );
};

export default Product;
